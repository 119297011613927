import aboutimg from "../../assets/aboutimg2.png"
import "./About.css"
import image1 from "../../assets/aboutblock1.png"
import image2 from "../../assets/DIGITAL PRESENCE.png"
import image3 from "../../assets/experience 1.png"
import image4 from "../../assets/quick support.png"
function About() {
    return <>
        <div className="AboutMain about">
            <div className="about_image">
                <img className="tu" src={aboutimg} alt="aboutimg"></img>
            </div>
            <div className="about_content">
                <div className="about_heading">
                    <h1>Reasons to count on us</h1>
                    <p>We dream of a world where small businesses are on equal footing with big brands. </p>
                </div>
                <div className="about_maincontent">
                    <div className="about_blocks1">
                        <div className="blocks1_block">
                            <div className="image1"><img src={image1} alt="image1"></img></div>
                            <div className="reason1">
                                <h1>Custom Software Development</h1>
                                <p>
                                Our team creates and deploys software to optimize your operations and streamline processes.</p>
                            </div>
                        </div>
                        <div className="blocks1_block">
                            <div className="image1"><img src={image2} alt="image1"></img></div>
                            <div className="reason1">
                                <h1>Digital Presence</h1>
                                <p>We enhance your brand's visibility and engagement through industry-leading digital presence services.</p>
                            </div>
                        </div>
                    </div>
                    <div className="about_blocks2">
                        <div className="blocks1_block">
                            <div className="image1"><img src={image3} alt="image1"></img></div>
                            <div className="reason1">
                                <h1>Experience</h1>
                                <p>With decades of web design and development expertise, your project is in capable hands.</p>
                            </div>
                        </div>
                        <div className="blocks1_block">
                            <div className="image1"><img src={image4} alt="image1"></img></div>
                            <div className="reason1">
                                <h1>Quick Support</h1>
                                <p>We provide quick and reliable IT and digital marketing support at your fingertips.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}

export default About;