import NavBar from "../../components/Navbar/NavBar";
import "./Main.css"
import { Link as ScrollLink } from 'react-scroll';


function Main(){


    return<>

        <div className="Main_div home">
            <NavBar></NavBar>
            <div className="center-content">
             <h1>KORSLET</h1>
             <h2>Your Tech Armour</h2>
             <ScrollLink to="contact" smooth={true} duration={800} >
             <button className="learnmore">Know More</button></ScrollLink>
             
            </div>
           
        </div>
        
        </>

}

export default Main;