import "./Skills.css"
import webskill2 from "../../assets/WebSkills/webskill2.png"
import webskill1 from "../../assets/WebSkills/webskill1.png"
import webskill3 from "../../assets/WebSkills/webskill3.png"
import webskill4 from "../../assets/WebSkills/webskill4.png"
import uiux1 from "../../assets/WebSkills/uiux1.png"
import uiux2 from "../../assets/WebSkills/uiux2.png"
import appdev1 from "../../assets/WebSkills/appdev1.png"
import appdev2 from "../../assets/WebSkills/appdev2.png"
import digi1 from "../../assets/WebSkills/digi1.png"
import digi2 from "../../assets/WebSkills/digi2.png"
import digi3 from "../../assets/WebSkills/digi3.png"
import digi4 from "../../assets/WebSkills/digi4.png"
import graphics1 from "../../assets/WebSkills/graphics1.png"
import graphics2 from "../../assets/WebSkills/graphics2.png"
import graphics3 from "../../assets/WebSkills/graphics3.png"
import graphics4 from "../../assets/WebSkills/graphics4.png"


function Skills() {
    return <>
    <div className="skillheading skills">
    <h1>Our Skills</h1>
            <p>“Driving client success through unmatched expertise and
                tailored solutions.”</p>
    </div>

        <div className="skillsmain">
            
            <div className="skillscontainer">
                
                <div className="wrapper">
                    <div className="item field"><h1>Web Development</h1></div>
                    <div className="item"><img src={webskill2} className="webskill1" alt="skill1"></img></div>
                    <div className="item"> <img src={webskill1} className="webskill"  alt="skill1" ></img></div>
                    <div className="item"><img src={webskill3} className="webskill"  alt="skill1"></img></div>
                    <div className="item"> <img src={webskill4} className="webskill"  alt="skill1"></img></div>

                </div>
                <div className="wrapper">
                    <div className="item field"><h1>Digital Marketing</h1></div>
                    <div className="item"><img src={digi1} className="uiuxskill"  alt="skill2"></img></div>
                    <div className="item"> <img src={digi2} className="uiuxskill" alt="skill2"></img></div>
                    <div className="item"> <img src={digi3} className="uiuxskill" alt="skill2"></img></div>
                    <div className="item"> <img src={digi4} className="uiuxskill" alt="skill2"></img></div>

                </div>
                <div className="wrapper">
                    <div className="item field"><h1>Graphics
                        Designing</h1></div>
                    <div className="item"> <img src={graphics4} className="uiuxskill" alt="skill3"></img></div>
                    <div className="item"> <img src={graphics3} className="uiuxskill" alt="skill3"></img></div>
                    <div className="item"><img src={graphics1} className="uiuxskill" alt="skill3"></img></div>
                    <div className="item"> <img src={graphics2} className="uiuxskill" alt="skill3"></img></div>



                </div>

                

                <div className="wrapper">
                    <div className="item field"><h1>App Development</h1></div>
                    <div className="item"><img src={appdev1} className="uiuxskill" alt="skill4"></img></div>
                    <div className="item"> <img src={appdev2} className="uiuxskill" alt="skill4"></img></div>


                </div>

                <div className="wrapper">
                    <div className="item field"><h1>UI/UX Services</h1></div>
                    <div className="item"><img src={uiux1} className="uiuxskill" alt="skill5"></img></div>
                    <div className="item"> <img src={uiux2} className="uiuxskill" alt="skill5"></img></div>


                </div>

            
               



            </div>
        </div>
        {/* <div ClassName="Skills_maindiv">
    
        


        <div className="wrapper">
            <div className="Skill">
                
            </div>
            <div className="Skill">
               
            </div>
            <div className="Skill">
         
            </div>
            <div className="Skill">
               
            </div>
           

        </div>

        
   
     
    </div> */}



    </>
}

export default Skills;