import "./Footer.css"


import contactimg from "../../assets/connectimg.png"

import logo from "../../assets/logo2.png"

import linkedin from "../../assets/linkedin 1.png"
import facebook from "../../assets/facebook 1.png"
import rights from "../../assets/allrights.png"
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import Iinstagram from "../../assets/instagram 1.png"
import { Link as ScrollLink} from 'react-scroll';

import { useState } from "react";
function Footer() {

    const textareaStyle = {
        width: '412px',   
        height: '70px',   
        border: '1px solid #A7AAAD',
        borderRadius: '6px',
        padding: '10px',
        resize: 'none',    
      };
      const isMobileScreen = window.matchMedia('(max-width: 768px)').matches;
      const isMobileScreen2 = window.matchMedia('(max-width: 991px)').matches;

      // Conditionally set the textarea width based on screen size
      if (isMobileScreen) {
        textareaStyle.width = '302px';
      }
      if (isMobileScreen2) {
        textareaStyle.width = '310px';
      }

      const [name ,setName] =useState('');
      const [companyname , setComapnyname ] =useState('');

      const [number , setNumber] =useState('');
      const [lastname , setLastname] =useState('');
      const [email ,setEmail] =useState('');
      const [message, setMessage] =useState('');


      const Submit=(e)=>{
        const formEle =document.querySelector("form");
        e.preventDefault()
        const formData =new  FormData(formEle)
        fetch("https://script.google.com/macros/s/AKfycbwwg6nZ3JrhcLDMk14Fp-oLKLvbJEPSKPNIMhjZzmnDWxCx0_j7aY_uWcRHaUsRoUjXaA/exec",{
          method:"POST",
          body: formData
        })

        toast("Successfully Submitted");
        console.log("submited")

        setName('');
            setLastname('');
            setComapnyname('');
            setNumber('');
            setEmail('');
            setMessage('');


          

            
      }
     

      // const handleChange = (e) => {
      //   const inputValue = e.target.value;
      //   if (/^\d{10}$/.test(inputValue)) {
          
      //     setNumber(inputValue);
      //   } else {
         
      //     alert('Please enter a 10-digit contact number.');
      //   }
      // };
      
      






    return <>




        <div className="footercontainer">
            <div className="try">
            <div className="contactmaindiv">
                <div className="contact_content row ">
                    <div className="detail col-6">
                        <h1>Talk To Us</h1>
                        <p>Unlock the Power of Technology and Marketing Excellence! Contact us today to access the best IT services and 
                          marketing strategies that will supercharge your business growth.</p>
                    </div>
                    <div className="contact_img col-6 ">
                        <img src={contactimg} alt="contactimg"></img>
                    </div>
                </div>
                <div className="contact_form contact">
                    <div className="form_container">
                        <div className="heading_main">
                            <h3>CONTACT US</h3>
                            <div className="small_line"></div>
                            <h2>Let's talk!</h2>
                        </div>
                        <div className="entries">
                          <form  onSubmit={(e)=>Submit(e)} >
                            <div className="name">
                                <div className='username'>

                                    <input
                                        type="text"
                                        name="Name"
                                       className="inputtext"
                                       onChange={(e)=>setName(e.target.value)}
                                       value={name}
                                        
                                        required
                                    />
                                    
                                </div>
                                <div className='Lastname'>

                                    <input
                                        type="text"
                                        name="user"
                                        className="inputtext"
                                        onChange={(e)=>setLastname(e.target.value)}
                                        value={lastname}
                                       
                                        required
                                    />
                                </div>
                            </div>






                            <div className="companydetails">
                                <div className='companyname'>

                                    <input
                                        type="text"
                                        name="CompanyName"
                                        className="inputtext"
                                        onChange={(e)=>setComapnyname(e.target.value)}
                                        value={companyname}
                                        required
                                    />
                                </div>
                                <div className='title'>

                                    <input
                                        type="tel"
                                        name="Number"
                                       
                                      
                                        className="inputtext"
                                        onChange={(e)=>setNumber(e.target.value)}
                                        value={number}
                                        pattern="\d{10}"
                                        required
                                    />
                                </div>
                            </div>

                            
                            <div className="email">
                                <div className='useremail'>

                                    <input
                                        type="email"
                                        name="Email"
                                        onChange={(e)=>setEmail(e.target.value)}
                                        value={email}
                                        
                                        required
                                    />
                                </div>
                          
                            </div>


                            <div className="form-group">
                            <textarea style={textareaStyle}
                            name="Message"
                               onChange={(e)=>setMessage(e.target.value)}
                               value={message}
                            
                            ></textarea>


                           
                           
                        </div>
                        <div className="submit-button">
                            
                      <button>Submit</button>
                      
                        </div>
                        <ToastContainer />

                        </form>
                        </div>
                    </div>
                </div>

                {/* <img className="lineimg" src={line}></img> */}
            </div>



            {/* <div className="line"><img className="lineimg" src={line}></img></div> */}


            <div className="FooterMain">
        <div className="FooterLogo col-sm-4 "><img className="korsletlogo" src={logo} alt="korsletimg" />
        <div className="socialmedia">
            <ul className="socialimg">
                <li>
                <a href=" https://www.linkedin.com/company/korslet/" >
                  <img src={linkedin} alt="linkedinimg"></img></a>
                  </li>
                <li>
                <a href="https://instagram.com/korsletofficial?igshid=OGQ5ZDc2ODk2ZA==" >
                  <img src={Iinstagram} alt="instaimg"></img></a>
                 
                  </li>
                  
               
                <li>
                <a href="   https://www.facebook.com/profile.php?id=100093211328743" >
                  <img src={facebook} alt="facebookimg"></img>
                  </a>
                </li>
            </ul>
        </div>
       </div>
        <div className='ContentFooter col-sm-8'>
      
         

       

          <div className="Support">
            <h2>Quick links</h2>
            <ul className="links">
              <li >
              <ScrollLink className='korslet' to="about" smooth={true} duration={500} >
            <li>About</li>
          </ScrollLink>
              </li>
              {/* <li>
               <Link className='bk'>contact US</Link> 
              </li> */}
              <li>
              <ScrollLink className='korslet' to="services" smooth={true} duration={500} >
            <li>Services</li>
          </ScrollLink>
              </li>
              <li>
              <ScrollLink className='korslet' to="skills" smooth={true} duration={500} >
            <li>Skills</li>
          </ScrollLink> 
              </li>
              {/* <li>
               <Link className='korslet' to="/refund">Contact Us</Link> 
              </li> */}
              {/* <li>
               <Link className='bk'>SiteMap</Link> 
              </li>
              <li>
               <Link className='bk'>Learning Resources</Link> 
              </li> */}
            </ul>
          </div>

          {/* <div className="Support">
            <h2>Support</h2>
            <ul className="links">
              <li >
               <Link className='korslet' to="/terms" >Terms and Conditions</Link> 
              </li>
            
              <li>
               <Link className='korslet' to="/privacy">Privacy Policy</Link> 
              </li>
              <li>
               <Link className='korslet'to="/refund">Refund and Return Policy</Link> 
              </li>
           
            </ul>
          </div> */}

          <div className="Contact">
            <div className='contactheading'><h2>Contact</h2></div>
            <div className='contactdetails'>
              
              <ul className='add'>
                <li>
                Ghodbunder road, Thane,Maharashtra, 400615
                </li>
                <li>
                  call :7263889831
                </li>
                <li>
                  official@korslet.com
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className="line"></div>

      <div className="rights">
        <img src={rights} alt="rightsimg"></img>
      </div>
      </div>
        </div>
    </>
}

export default Footer;