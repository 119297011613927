import React from 'react'
import Main from './Main/Main';
import About from './AboutUs/About';
import Services from './Services/Services';
import Skills from './Skills/Skills';
import Footer from '../components/footer/Footer';



function Home() {
  
  return (
    <>
<Main></Main>
<About></About>
<Services></Services>
<Skills></Skills>
<Footer></Footer>


    </>
  )
}

export default Home;