import "./Services.css"

import num1 from "../../assets/num1.png"
import num2 from "../../assets/num3 (1).png"
import num3 from "../../assets/num3 (2).png"
function Services() {

    return <>
        <h1 className="services">Our Services</h1>
        <p className="service_tagline">Transforming ideas into reality through innovative solutions and exceptional services.</p>
        {/* <div className="nums">
            <ul className="nums_ul">
                <li><img src={num1}></img></li>
                <li><img src={num1}></img></li>
                <li><img src={num1}></img></li>
            </ul>
        </div> */}
        <div className="container">

            <div className="cardmain">
                <div className="number1"><img src={num1} alt="img1"></img></div>
                <div className="card">
                    <div className="num1_div"><img src={num1} alt="img2"></img></div>
                    <div className="imagedigi">
                        <div className="intro">
                            <h1>APP Development</h1>
                            <p>
                                High performing and customized mobile app ensuring seamless user experience. We deliver robust and fully functioning mobile apps.                                            </p>
                        </div></div>



                </div>
            </div>


            <div className="cardmain">
                <div className="number1"><img src={num3} alt="img3"></img></div>
                <div className="card card1">
                    <div className="num1_div"><img src={num1} alt="img4"></img></div>
                    <div className="imagedigi">
                        <div className="intro">
                            <h1>Web Development</h1>
                            <p>we have a team of professional experts who are excelled in making a wide range of web development such as static websites ,
                                dynamic websites and E-commerce websites .</p>
                        </div></div>


                </div>
            </div>
            <div className="cardmain">
                <div className="number1"><img src={num2} alt="img5"></img></div>
                <div className="card card2">
                    <div className="num1_div"><img src={num1} alt="img6"></img></div>
                    <div className="imagedigi">
                        <div className="intro">
                            <h1>Digital Marketing</h1>
                            <p>
                                Boost your business ranking and presence online with the right digital marketing services from our digital marketing experts.                                            </p>
                        </div></div>


                </div>
            </div>
        </div>

    </>
}

export default Services;