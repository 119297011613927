import "./NavBar.css";

import logo from "../../assets/KL.png"
import { FaBars } from "react-icons/fa"
import { ImCross } from "react-icons/im"
import React, { useState } from "react";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';



function NavBar() {
  const [Mobile, setMobile] = useState(false);

  // Scroll to the top when the logo or home link is clicked
  const scrollToTop = () => {
    scroll.scrollToTop();
    setMobile(false); 
  };

  return (
    <nav className="navlinks">
      <img className="korslet_logo" src={logo} alt="logo" onClick={scrollToTop} />
      <div className="navbuttonlinks">
        <ul className={Mobile ? 'nav-links-mobile' : 'nav-links'}>
          {/* Use ScrollLink for smooth scrolling */}
          {/* <ScrollLink to="home" smooth={true} duration={500} onClick={() => setMobile(false)}>
            <li>Home</li>
          </ScrollLink> */}
          <ScrollLink to="about" smooth={true} duration={500} onClick={() => setMobile(false)}>
            <li>About</li>
          </ScrollLink>
          <ScrollLink to="services" smooth={true} duration={500} onClick={() => setMobile(false)}>
            <li>Services</li>
          </ScrollLink>
          <ScrollLink to="skills" smooth={true} duration={500} onClick={() => setMobile(false)}>
            <li>Skills</li>
          </ScrollLink>
         
         
        </ul>
         <ScrollLink to="contact" smooth={true} duration={800} onClick={() => setMobile(false)}>
                    <button className="nav-support">Contact</button>
          </ScrollLink>

      </div>
      <button className="mobile-menu-icon" onClick={() => setMobile(!Mobile)}>
        {Mobile ? <ImCross style={{ color: 'white' }} /> : <FaBars style={{ color: 'white' }} />}
      </button>
    </nav>
  );
}

export default NavBar;



// function NavBar() {


//   const [Mobile, setMobile] = useState(false)

//   return <>
//     <nav className="navlinks">


//       <img className="korslet_logo" src={logo} alt="logo"></img>
//       <div className="navbuttonlinks">
//         <ul className={Mobile ? "nav-links-mobile" : "nav-links"} onClick={() => setMobile(false)}>
//           <Link to='/' className="add1"><li>Home</li></Link>
//           <Link to='/about' className="add1"><li>About</li></Link>
//           <Link to='/services' className="add1"><li>Services</li></Link>
//           <Link to='/contact' className="add1"><li>Features</li></Link>
//           <Link to='/contact' className="add1"><li>Domains</li></Link>
//           <Link to='/contact' className="add1"><li>Contact</li></Link>



//         </ul>
//         <button className="nav-support">Support</button>

//       </div>

//       <button className="mobile-menu-icon" onClick={() => setMobile(!Mobile)}>
//         {Mobile ? (
//           <ImCross style={{ color: 'white' }} /> // Change color to white when Mobile is true
//         ) : (
//           <FaBars style={{ color: 'white' }} /> // Change color to white when Mobile is false
//         )}
//       </button>



//     </nav>







//   </>
// }

// export default NavBar;